// main
import { Helmet } from "react-helmet";

import ContentFormat from "../content/webz/content-format-xw";
import WebbHeader from "../content/webz/webb-header-xw";
import WebbFooterMobile from "../content/webz/webb-footer-mobile";

import WebbDividerMedium from "../content/webx/webb-divider-md";
import WebbDividerSmall from "../content/webx/webb-divider-sm";
import WebbLoaderSmall from "../content/webx/webb-loader-sm";


import AssetsListMarketModule from "../content/assets/assets-list-market";
import UserActionsModule from "../content/home/user-actions";

import TransfersFundsListWaitModule from "../content/transfers.funds/transfers-funds-list-wait";
import TransfersAssetListWaitModule from "../content/transfers.assets/transfers-assets-list-wait";
import { ContractListPendingModule } from "../content/contracts/contracts-list-pending";
import { OffersListPendingModule } from "../content/offers/offers-list-pending";


export default function HomeUser () {
  
  const metadata = {
    name: 'Home',
    banner: {link: 'https://img.freepik.com/premium-vector/futuristic-vector-hexagon-wave-dark-cyberspace-abstract-wave-with-dots-line-white-moving-particles-background_744733-97.jpg?w=900'}
  }


  return(

  <>
    <Helmet>
      <title>{metadata.name}{' • '}{process.env.REACT_APP_WEBB_SITE_NAME}{' • '}{process.env.REACT_APP_WEBB_SITE_LINE}</title>
      <link rel="canonical" href={process.env.REACT_APP_WEBB_SITE_LINK} />
    </Helmet>

    <ContentFormat 
      
      name = {metadata.name}
      header = {{ size: 'medium', show: true, data: 
        <> 
          <WebbHeader data={{name: metadata.name, home: '/', link:''}} /> 
          
        </> }}

      media = {{ size: 'fluid', show: false, data: <></> }}

      content = {{ size: 'medium', show: true, data: 
      <>
        <WebbDividerSmall />
      
        <h2 className='text-normal m-0 ms-3'>{'Featured Projects'}</h2>
        <WebbDividerSmall />
        <AssetsListMarketModule />

        <WebbDividerMedium />
        <UserActionsModule />

        <WebbDividerMedium />

        <h2 className='text-normal m-0 ms-3'>{'Pending Document Signature'}</h2>
        <WebbDividerSmall />
        <ContractListPendingModule />
        <WebbDividerMedium />

        <WebbDividerSmall />
        <h2 className='text-normal m-0 ms-3'>{'Pending Fund Transfers'}</h2>
        <WebbDividerSmall />
        <TransfersFundsListWaitModule />

        <WebbDividerMedium />
        <WebbDividerSmall />
        <h2 className='text-normal m-0 ms-3'>{'Pending Asset Transfers'}</h2>
        <WebbDividerSmall />
        <TransfersAssetListWaitModule />


        <WebbDividerMedium />
        <WebbDividerSmall />
        <h2 className='text-normal m-0 ms-3'>{'Pending Assets Offers'}</h2>
        <WebbDividerSmall />
        <OffersListPendingModule />

        <WebbDividerMedium />
        <WebbDividerMedium />
        <WebbDividerMedium />
        <WebbDividerMedium />
        <WebbDividerMedium />
        <WebbDividerMedium />
      </>
      }}
    
      footer = {{ size: 'medium', show: true, data: 
      <> 
        <div className="text-center back-color-lite">
          
          <WebbFooterMobile /> 
        </div>
      </> }}
    
    ></ContentFormat>


  </>
  )
}