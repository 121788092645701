// auth - firebase mail link
import { useEffect, useState } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";

import Jazzicon, { jsNumberForAddress } from 'react-jazzicon';

import { NumberFormat } from "../../services/srvc-utilities";

import WebbDividerMedium from "../webx/webb-divider-md";
import WebbDividerSmall from "../webx/webb-divider-sm";

import { GetUserForm } from "../../services/srvc-utilities";
import { GetLocalUser, GetLocalBusiness } from "../../services/srvc-auth-local";

import { UnitDetails } from "../../services/srvc-assets-realm";
import { TransfersFundAssetListUnits } from "../../services/srvc-transfers-funds-realm";
import { NAVSChangeList } from "../../services/srvc-navs-realm";
import NavLineChart from "../webx/dashboard-navs-chart";
import { AssetsOffersCreate } from "../../services/srvc-offers-realm";

export default function AssetUnitDetailsInvestorModule () {

  const usxx = GetUserForm()
  const usrx = GetLocalUser()
  const temx = GetLocalBusiness()
  const asset = usxx === 'user' ? usrx : temx

  const navigate = useNavigate();
  const {id} = useParams()

  const [loader, setLoader] = useState(true);
  
  const [data, setData] = useState()
  const [users, setUsers] = useState()

  const [myShare, setMyShare] = useState()

  const [saleUnit, setSaleUnit] = useState('')
  const [saleRate, setSaleRate] = useState('')
  const [saleUserMail, setSaleUserMail] = useState('')
  const [saleUserName, setSaleUserName] = useState('')
  const [submit, setSubmit] = useState(false)


  useEffect( () => {
    if (asset){

      const fetchData = async() => {
        setLoader(true);

        const resUnit = await UnitDetails({data: {item: id}, srvc: '******'})
        if (resUnit.stat) setData(resUnit.data)

        var resp = await NAVSChangeList({data:{unit:id}})
        // console.log(resp)

        setLoader(false);
      }
      fetchData()
    } else {}
  },[id]);

  useEffect( () => {
    if (asset){

      const fetchData = async() => {
        setLoader(true);

        const result = await TransfersFundAssetListUnits({data: {item: id}, srvc: '******'})
        // console.log(result)
        if (result.stat) {
          setUsers(result.data.list)
          setMyShare(result.data.list.find(entry=> entry.user.item == asset.item))
        }
        // console.log (result)

        setLoader(false);
      }
      fetchData()
    } else {}
  },[id]);

  // console.log(myShare)


  const handleOfferSubmit = async()=>{

    setSubmit(true)

    var datx = {
      cred: { name: saleUserName, mail:saleUserMail, item:''},
      debt: { name: asset.name, mail: asset.mail, item: asset.item },
      asset: { item: ""},
      unit : { item: id},
      rate: { number : saleRate, ticker: "INR"},
      size: { number: saleUnit, ticker: "BRX"}
    }
    console.log(datx)
    var res = await AssetsOffersCreate({data: datx})
    // console.log(res)
    if(res.stat) window.location.reload(true)

  }


  if (loader) return <>Please Wait...</>

  return (
  <>

    {/* info */}
    <div className="">
      <p className="text-normal m-0"></p>
    </div>
      
    {/* asset */}
    <div className="rounded-xd back-color-wite border">

      <div className="d-flex p-3">

        <div className="" style={{width: '2.7rem', height: '2.7rem'}}>
          <div className="media-cube" >
            <img src={data?.media?.link} className="rounded-xx" alt={data?.name || ''}></img>
          </div>
        </div>

        <div className="ms-2">
          <p className="text-normal text-bold m-0 text-sm">{data?.meta?.name || '******'}</p>
          <p className="text-color-next m-0">ID: {data?.webx?.number || '******'}</p>
        </div>
      </div>

      <div className="px-3">
        <p className="m-0 text-wd">{data?.meta?.memo || '******'}</p>
      </div>

      <WebbDividerSmall />
      <div className="px-3">
        <p className="text-color-main m-0 p-0">
          <span>{'Rate:  '}</span>
          <span className="">
            {(((parseInt(data?.rate?.number || 0)))/1000000).toFixed(2)}
          </span>
          <span>{' '}</span>
          <span className="text-small">{data?.rate?.ticker || '*'}/SQFT</span>
        </p>        
      </div>
      <WebbDividerSmall />

      <div className="px-3">
        <p className="m-0 text-wd mb-4 text-primary " data-bs-toggle="modal" data-bs-target="#exampleModal">Create Offer</p>
      </div>

    </div>

       {/* Modle For Offer Create  */}
        <div class="modal fade" id="exampleModal" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
          <div class="modal-dialog">
            <div class="modal-content">
              <div class="modal-header">
                <h1 class="modal-title fs-5" id="exampleModalLabel">Create Offer</h1>
                <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
              </div>
              <div class="modal-body">
                <div className="">
                      <label className="text-small mb-1">Unit For Sale</label>
                      <input value={saleUnit} onChange={(e)=> setSaleUnit(e.target.value) } className="form-control"/>
                      <label className="text-small mb-1 mt-3">Unit Rate  @Sqft</label>
                      <input value={saleRate} onChange={(e)=> setSaleRate(e.target.value) } className="form-control"/>
                      <label className="text-small mb-1 mt-3">Client Name</label>
                      <input value={saleUserName} onChange={(e)=> setSaleUserName(e.target.value) } className="form-control"/>
                      <label className="text-small mb-1 mt-3">Client Email</label>
                      <input value={saleUserMail} onChange={(e)=> setSaleUserMail(e.target.value) } className="form-control"/>
                </div>
              </div>
              <div class="d-flex mx-3">
                <div className="me-auto">
                  <button type="button" class="btn btn-white text-small rounded" data-bs-dismiss="modal">Close</button>
                </div>
                <div className="text-end">
                   <button disabled={submit || saleRate =='' || saleRate < 1 || saleUnit =='' || saleUnit < 1 || saleUserName == ''||  saleUserMail == ''} onClick={()=> handleOfferSubmit()} type="button" class="btn btn-outline-primary text-small rounded">Create</button>
                </div>
                
                
              </div>
            </div>
          </div>
        </div>




    

    <div className="p-2 bg-white  rounded mt-3 d-flex">
      <div className="me-auto text-center " style={{width:'50%'}}>
        <h6 className="fw-bold mt-2">Current Rate</h6>
        <p>{(((parseInt(data?.rate?.number || 0)))/1000000).toFixed(2)}</p>
      </div>
      <div className="text-end text-center" style={{width:'50%'}}>
        <h6 className="fw-bold mt-2">Market Value</h6>
        <p>{NumberFormat((data?.rate?.number) * ((parseInt(myShare?.sale?.number || 0))/1000000), "w","2")}</p>
      </div>
      <div className="text-end text-center" style={{width:'50%'}}>
        <h6 className="fw-bold mt-2">My Share</h6>
        <p className="m-0">
            {`${((data?.units?.mint !=='0' ? (myShare?.sale?.number/data?.units?.mint) : 0)*100).toFixed(0)}%`}
       </p> 
     </div>
    </div>  

    <div>
        
    </div> 

    <div className="bg-white mt-3 " style={{height:"400px"}}>
    <NavLineChart unit={id} rate={(((parseInt(data?.rate?.number || 0)))/1000000).toFixed(2)} />
    </div>
    
    {/* sales */}
    <WebbDividerSmall />
    <div className="rounded-xd back-color-wite border p-3">
      <div className="d-flex text-dark">
        <div className="">
          <p className="m-0">
            {`SOLD: ${((data?.units?.mint !=='0' ? (data?.units?.book/data?.units?.mint) : 0)*100).toFixed(0)}%`}
          </p>
        </div>
            
        <div className="ms-auto text-end">
          <span className="text-bold">{NumberFormat((data?.units?.book || '0'), 'www', '0')}</span>
          <span>{'/'}</span>
          <span>{NumberFormat((data?.units?.mint || '0'), 'www', '0')}</span>
          <span className="text-small">{' SQFT'}</span>
        </div>  
      </div>

      <div className="">
        <div className="progress" role="progressbar" style={{height: '0.27rem'}}>
          <div className="progress-bar progress-bar-striped progress-bar-animated back-color-success" 
            style={{width:`${(data?.units?.book/data?.units?.mint)*100}%`, height: '0.27rem'}}>
          </div>
        </div>
      </div>
          
      <div className="d-flex text-dark d-none">
        <div className=""><p className="text-small text-color-tone m-0 mb-1">Units: {data?.units?.ticker || 'BRX'}</p></div>
        <div className="ms-auto text-end">{NumberFormat((data?.units?.mint || '0'), 'www', '0')}</div>  
      </div>

    </div>
    {/* my share scale */}
    <div className="rounded-xd back-color-wite border p-3 mt-3 d-none">
      <div className="d-flex text-dark">
        <div className="">
          <p className="m-0">
            {`MY SHARE: ${((data?.units?.mint !=='0' ? (myShare?.sale?.number/data?.units?.mint) : 0)*100).toFixed(0)}%`}
          </p>
        </div>
            
        <div className="ms-auto text-end">
          <span className="text-bold">{NumberFormat((myShare?.sale?.number || '0'), 'www', '0')}</span>
          <span>{'/'}</span>
          <span>{NumberFormat((data?.units?.mint || '0'), 'www', '0')}</span>
          <span className="text-small">{' SQFT'}</span>
        </div>  
      </div>

      <div className="">
        <div className="progress" role="progressbar" style={{height: '0.27rem'}}>
          <div className="progress-bar progress-bar-striped progress-bar-animated back-color-success" 
            style={{width:`${(myShare?.sale?.number/data?.units?.mint)*100}%`, height: '0.27rem'}}>
          </div>
        </div>
      </div>
          
      <div className="d-flex text-dark d-none">
        <div className=""><p className="text-small text-color-tone m-0 mb-1">Units: {data?.units?.ticker || 'BRX'}</p></div>
        <div className="ms-auto text-end">{NumberFormat((data?.units?.mint || '0'), 'www', '0')}</div>  
      </div>

    </div>

    


    {/* users */}
    <WebbDividerMedium />
    <div className="mx-3">
      <p className="text-normal m-0">Users (Share Holders)</p>
    </div>

    <WebbDividerSmall />
    <div className="rounded-xd back-color-wite border">
      {users && users.map((item, i) => (
      <div key={i}>
        <div className="d-flex rounded p-3">
          <div className="me-auto" style={{width: '60%'}}>
            <p className="m-0 text-sm">
              <span className="align-middle">
                <Jazzicon diameter={20} seed={jsNumberForAddress(item?.user?.item || Date.now().toString())} />  
              </span>
              <span className="ms-2 align-middle">{item?.user?.item}</span>
              <span>
              <div className="d-none">
                <div className="progress" role="progressbar" style={{height: '0.27rem'}}>
                  <div className="progress-bar progress-bar-striped progress-bar-animated back-color-success" 
                    style={{width:`${(item?.sale?.number/data?.units?.mint)*100}%`, height: '0.27rem'}}>
                  </div>
                </div>
              </div>
              </span>
            </p>
          </div>
          <div className="">
            <p className="m-0 text-sm">
              <span className="">{NumberFormat((item?.sale?.number || 0), '', '0')}</span>
              <span>{' '}</span>
              <span className="text-small text-color-tone">{'SQFT'}</span>
            </p>
          </div>
        </div>
        <div className="border-bottom"></div>
      </div>
      ))}
    </div>

  </>

  )
}